import React from "react";
import get from "lodash.get";
import { VStat } from "components/General/Stat";

const ClubStats = (props: any) => {
  const club = get(props, "club", {});
  const statKeyAndLocations: any = {
    members: "membersonly_count",
    Posts: "feed_count",
    Events: "events_count",
  };
  const parsedStats = Object.keys(statKeyAndLocations).map((e, index) => {
    return (
      <VStat key={index} text={e} stat={club[statKeyAndLocations[e]]}></VStat>
    );
  });
  return (
    <div className="hContainer spaceEvenly alignCenter centerText">
      {parsedStats}
    </div>
  );
};

export default ClubStats;
