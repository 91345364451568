import React from "react";
import get from "lodash.get";
import ActionHOC from "./ActionHOC";

const ProfileImage = (props: any) => {
  const src = get(props, "src", "");
  const onClick = get(props, "onClick", () => {});

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
      onClick={onClick}
    >
      <img
        src={src}
        style={{
          height: "100%",
          width: "100%",
          objectFit: "contain",
          borderRadius: "50%",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: "white",
        }}
        alt="Profile Image"
      />
    </div>
  );
};

export default ActionHOC(ProfileImage);
