import React from "react";
import get from "lodash.get";
import { useQuery } from "@apollo/client";
import { eventsReservations } from "graphql/queries/eventQueries";
import PufIcon from "components/General/PufIcons";
import ProfileImage from "components/General/ProfileImage";

const Attendees = (props: any) => {
  const eventId = get(props, "eventId", -1);

  const { loading, error, data } = useQuery(eventsReservations, {
    fetchPolicy: "no-cache",
    variables: { event_id: eventId },
  });

  if (loading) {
    return null;
  }

  const reservations = get(data, "eventReservations.data", []);

  console.log(data);
  const profileImages = reservations.map((e: any, index: number) => {
    const driverImage = get(e, "driver.default_image", "");
    if (driverImage) {
      return (
        <div
          className="hSimpleContainer"
          style={{ height: "100%" }}
          key={index}
        >
          <div style={{ width: 35 }}>
            <ProfileImage src={driverImage} />
          </div>
          {index < reservations.length - 1 ? (
            <div className="hSpace" style={{ height: 10 }} />
          ) : null}
        </div>
      );
    }
    return null;
  });

  return (
    <div>
      <div
        className="hSimpleContainer flexCenter fontSize alignCenter SFBold"
        style={{}}
      >
        <PufIcon set1={0} icon="Circle-Check-Icon" />
        <div className="hSpace" />
        Attending Drivers
      </div>
      <div className="space" />
      <div
        className="hContainer flexCenter"
        style={{
          height: 35,
          maxWidth: "100%",
          overflowX: "scroll",
          overflowY: "hidden",
        }}
      >
        {profileImages}
      </div>
    </div>
  );
};

export default Attendees;
