import gql from 'graphql-tag';

export const classFragment = gql`
  fragment classFrag on Class {
    __typename
    vehicle_class_id
    name
    description
    badge
    active
    priority
    created_at
    updated_at
    logo
    color
  }
`;

export const subClassFragment = gql`
  fragment subClassFragment on SubClass {
    v_sub_class_id
    name
    description
  }
`;
