import gql from "graphql-tag";
import { manufacturerFragment } from "graphql/fragments/manufacturerFragments";
import { classFragment } from "graphql/fragments/classFragments";

export const classificationFragment = gql`
  fragment classificationFrag on Classification {
    __typename
    vehicle_classification_id
    name
    vehicle_manufacturer_id
    is_active
    vehicle_class_id
    created_at
    updated_at
    manufacturer {
      ...manufacturerFrag
    }
    vehicle_class {
      ...classFrag
    }
  }
  ${manufacturerFragment}
  ${classFragment}
`;
