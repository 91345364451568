import React from "react";
import ActionHOC from "components/General/ActionHOC";
import get from "lodash.get";

const FollowButton = (props: any) => {
  const onClick = get(props, "onClick", () => {});
  return (
    <button
      className={"button fontSizeSmall"}
      style={{ color: "white", padding: 2 }}
      onClick={onClick}
    >
      FOLLOW
    </button>
  );
};

export default ActionHOC(FollowButton);
