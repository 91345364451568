import React from "react";
import logo from "./logo.svg";
import "./App.scss";
import { ApolloProvider } from "@apollo/client";
import client from "./config/apolloConfig";
import EventProfile from "./pages/EventProfile";
import DriverProfile from "./pages/DriverProfile";
import ClubProfile from "./pages/ClubProfile";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "pages/Home";

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/event/:eventId">
              <EventProfile />
            </Route>
            <Route path="/garage/:driverId">
              <DriverProfile />
            </Route>
            <Route path="/club/:clubId">
              <ClubProfile />
            </Route>
          </Switch>
        </BrowserRouter>
      </div>
    </ApolloProvider>
  );
}

export default App;
