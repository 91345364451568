import React from "react";
import mockDriver from "utils/mockDriver";
import DriverRow from "components/Driver/DriverRow";
import FollowButton from "components/Driver/FollowButton";
import Share from "components/General/Share";
import HorizontalBadges from "components/Badges/HorizontalBadges";
import mockBadges from "utils/mockBadges";
import DriverStats from "components/Driver/Stats";
import { Tabs } from "components/General/Tabs";
import Garage from "components/Driver/Garage";
import get from "lodash.get";
import { useQuery } from "@apollo/client";
import { getDriverQueryStatsCity } from "graphql/queries/driverQueries";
import { useParams } from "react-router-dom";

const tabNames = ["GARAGE", "MEDIA", "CLUBS", "EVENTS", "BIO"];

const DriverProfile = () => {
  //const driver = mockDriver;
  const { driverId } = useParams();
  const { loading, error, data } = useQuery(getDriverQueryStatsCity, {
    fetchPolicy: "no-cache",
    variables: { driver_id: Number.parseInt(driverId) },
  });
  if (loading) {
    return null;
  }
  const driver = get(data, "anonymusDriver.data", null);
  if (driver) {
    console.log({ data });
    const badges = get(driver, "stats.classes", []);
    const garage = get(driver, "garage", []);
    const stats = get(driver, "stats", {});

    return (
      <div className={"vContainer"}>
        <div className="hContainer">
          <DriverRow driver={driver}></DriverRow>
          <div
            style={{
              display: "flex",

              justifyContent: "space-evenly",

              flexDirection: "column",
              alignItems: "center",
              padding: 10,
            }}
          >
            <FollowButton />
            <Share text={"SHARE GARAGE"} />
          </div>
        </div>
        <div className="hContainer alignCenter fontSizeSmall">
          <div className="hSpace" />
          <div
            style={{
              width: "30%",
            }}
          >
            <HorizontalBadges badges={badges} height={"7vw"}></HorizontalBadges>
          </div>
          <DriverStats stats={stats} />
        </div>
        <div className="space" />
        <Tabs tabs={tabNames} />
        <Garage garage={garage} default={driver.default_vehicle} />
      </div>
    );
  }
  return null;
};
export default DriverProfile;
