import gql from "graphql-tag";
import { eventFullFragment } from "graphql/fragments/eventFragments";
import { classFragment } from "graphql/fragments/classFragments";
import { driverFragment } from "graphql/fragments/driverFragments";
import { vehicleFragment } from "graphql/fragments/vehicleFragments";

export const GET_EVENT = gql`
  query anonymusEvent($venue_event_id: Int!) {
    anonymusEvent(venue_event_id: $venue_event_id) {
      ...eventFullFragment
      car_club {
        owner {
          user_id
          driver {
            driver_id
            username
          }
        }
      }
    }
  }
  ${eventFullFragment}
`;

export const getEventBadges = gql`
  query eventBadges($venue_event_id: Int!) {
    eventBadges(venue_event_id: $venue_event_id) {
      msg
      code
      data {
        ... on Class {
          ...classFrag
        }
      }
    }
  }
  ${classFragment}
`;

export const eventsReservations = gql`
  query eventReservations($nextPageUrl: String, $page: Int, $event_id: Int) {
    eventReservations(
      nextPageUrl: $nextPageUrl
      page: $page
      event_id: $event_id
    ) {
      msg
      code
      next_page_url
      data {
        __typename
        ... on Vehicle {
          driver {
            ...driverFragment
          }
          ...vehicleFrag
        }
      }
    }
  }
  ${driverFragment}
  ${vehicleFragment}
`;
