import React from "react";
import get from "lodash.get";
import ProfileImage from "components/General/ProfileImage";
import { GeneralInfo } from "components/General/InfoComponents";
import PufIcon from "components/General/PufIcons";

const DriverRow = (props: any) => {
  const driver = get(props, "driver", {});
  const verified = get(driver, "verified", false);

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
      <div style={{ height: "20vmin", width: "20vmin", padding: 10 }}>
        <ProfileImage src={driver.default_image} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          textAlign: "left",
        }}
      >
        <div className="hSimpleContainer fontSizeBig SFBold alignCenter">
          {driver.username}
          {verified ? (
            <>
              <div className="hSpace" />
              <img
                src={require("assets/images/verified.png")}
                style={{ height: 16 }}
              />
            </>
          ) : null}
        </div>
        <div className="fontSizeSmall">
          <div>{driver.tagline}</div>
          <GeneralInfo
            text={driver.city.name + " " + driver.city.state.abbreviation}
            icon={
              <PufIcon
                icon="Location-Icon"
                size={12}
                set1={0}
                className="smallPaddingRight"
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DriverRow;
