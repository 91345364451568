import gql from 'graphql-tag';
import {paymentTypeFragment} from './paymentTypeFragments';

export const eventFullFragment = gql`
  fragment eventFullFragment on Event {
    __typename
    venue_event_id
    name
    default_image
    address
    cost
    city_id
    official
    public
    public_cc_event
    private_cc_event
    available_spots
    spots
    unlimited_spots
    start_time
    end_time
    allowed_classes
    allowed_sub_classes
    restrict_makes
    code
    active
    car_club {
      __typename
      name
      car_club_id
    }
    active_reservations_count
    requires_signup_code
    requires_payment
    max_driver_guests
    guests
    guest_price
    allow_guests
    web_page
    sign_up_page
    summary
    payment_type {
      ...paymentTypeFragment
    }
    payment_type_id
    unlimited_guests
    owner_role {
      __typename
      name
      car_club_id
    }
    sponsors
  }
  ${paymentTypeFragment}
`;

export const eventFragment = gql`
  fragment eventFrag on PaginatedData {
    __typename
    ... on Event {
      ...eventFullFragment
    }
  }
  ${eventFullFragment}
`;

export const reservationFragment = gql`
  fragment reservationFrag on PaginatedData {
    __typename
    ... on Event {
      venue_event_id
      name
      default_image
      address
      cost
      city_id
      official
      public
      public_cc_event
      private_cc_event
      car_club_id
      car_club {
        __typename
        name
        car_club_id
      }
      spots
      unlimited_spots
      start_time
      end_time
      allowed_classes
      allowed_sub_classes
      restrict_makes
      code
      active
      active_reservations_count
      requires_signup_code
      requires_payment
      max_driver_guests
      guests
      guest_price
      allow_guests
      web_page
      sign_up_page
      summary
      payment_type {
        ...paymentTypeFragment
      }
      payment_type_id
      unlimited_guests
      sponsors
    }
  }
  ${paymentTypeFragment}
`;

export const eventFragmentForFeed = gql`
  fragment eventFragForFeed on PaginatedData {
    __typename
    ... on Event {
      ...eventFullFragment
    }
  }
  ${eventFullFragment}
`;
