import React from "react";
import ClubRow from "components/Club/ClubRow";
import { useQuery } from "@apollo/client";
import { getCarClub } from "graphql/queries/clubQueries";
import { useParams } from "react-router-dom";
import get from "lodash.get";
import { Tabs } from "components/General/Tabs";
import AboutItem from "components/Club/AboutItem";
import PufIcon from "components/General/PufIcons";
import {
  IoIosClipboard,
  IoIosLink,
  IoIosCalendar,
  IoIosHand,
  IoIosAt,
} from "react-icons/io";
import { GeneralInfo } from "components/General/InfoComponents";
import {
  FaPhone,
  FaEnvelope,
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaSnapchat,
  FaGlobe,
  FaInstagram,
  FaGlobeAmericas,
} from "react-icons/fa";
import GeneralAction from "components/General/GeneralAction";

const ClubProfile = () => {
  const { clubId } = useParams();
  const { loading, error, data } = useQuery(getCarClub, {
    variables: { car_club_id: Number.parseInt(clubId) },
  });
  const size = 30;
  if (!loading) {
    const carClub = get(data, "anonymusCarClub.data", {});
    console.log({ carClub });
    const name = get(carClub, "name", "");
    const description = get(carClub, "description", "");
    const paymentLink = get(carClub, "payment_link", "");
    const yearEstablished = get(carClub, "year_established", "");
    const leadTeam = get(carClub, "lead_team", []);
    let contactInfo = get(carClub, "contact_info", null);
    let socialMedia = get(carClub, "social_media", null);
    let parsedLeadTeam = null;
    if (leadTeam && Array.isArray(leadTeam)) {
      parsedLeadTeam = leadTeam.map((e, index) => {
        return (
          <div key={index} onClick={GeneralAction}>
            <div className="smallSpace"></div>
            {e}
          </div>
        );
      });
    }
    let parsedContactInfo = null;
    if (contactInfo && typeof contactInfo == "string") {
      try {
        contactInfo = JSON.parse(contactInfo);
      } catch (e) {
        contactInfo = null;
      }
      parsedContactInfo = Object.keys(contactInfo).map((e, index) => {
        if (icons[e]) {
          let Icon = icons[e];
          return (
            <div className="vContainer" key={index}>
              <div className="smallSpace" />
              <GeneralInfo
                icon={<Icon size={12} className="simplePaddingRight" />}
                text={contactInfo[e]}
              />
            </div>
          );
        }
        return null;
      });
    }
    let parsedSocialMedia = null;
    if (socialMedia && typeof socialMedia == "string") {
      try {
        socialMedia = JSON.parse(socialMedia);
      } catch (e) {
        socialMedia = null;
      }
      parsedSocialMedia = Object.keys(socialMedia).map((e, index) => {
        if (icons[e]) {
          let Icon = icons[e];
          return (
            <div className="vContainer" key={index}>
              <div className="smallSpace" />
              <GeneralInfo
                key={index}
                icon={<Icon size={12} className="simplePaddingRight" />}
                text={socialMedia[e]}
              />
            </div>
          );
        }
        return null;
      });
    }
    return (
      <div>
        <div className="space" />
        <ClubRow club={carClub} />
        <div className="space" />
        <Tabs tabs={["FEED", "MEMBERS", "EVENTS", "ABOUT"]} active={3} />
        <div className="fontSizeSmall">
          {name ? (
            <>
              <div className="space" />
              <AboutItem
                title={titles.CLUB_NAME}
                icon={<PufIcon icon="Club-Icon" size={size} set1={0} />}
              >
                {name}
              </AboutItem>
            </>
          ) : null}
          {description ? (
            <>
              <div className="space" />
              <AboutItem
                title={titles.CLUB_DESCRIPTION}
                icon={<IoIosClipboard size={size} />}
              >
                {description}
              </AboutItem>
            </>
          ) : null}
          {paymentLink ? (
            <>
              <div className="space" />
              <AboutItem
                title={titles.CLUB_PAYMENT}
                icon={<IoIosLink size={size} />}
              >
                {paymentLink}
              </AboutItem>
            </>
          ) : null}
          {yearEstablished ? (
            <>
              <div className="space" />
              <AboutItem
                title={titles.YEAR_ESTABLISHED}
                icon={<IoIosCalendar size={size} />}
              >
                {yearEstablished}
              </AboutItem>
            </>
          ) : null}
          {parsedLeadTeam ? (
            <>
              <div className="space" />
              <AboutItem
                title={titles.LEADERSHIPS}
                icon={<PufIcon icon="Group-User-Icon" size={size} set1={0} />}
              >
                {parsedLeadTeam}
              </AboutItem>
            </>
          ) : null}
          {parsedContactInfo ? (
            <>
              <div className="space" />
              <AboutItem
                title={titles.CONTACT_INFO}
                icon={<IoIosHand size={size} />}
              >
                {parsedContactInfo}
              </AboutItem>
            </>
          ) : null}
          {parsedSocialMedia ? (
            <>
              <div className="space" />
              <AboutItem
                title={titles.SOCIAL_MEDIA}
                icon={<IoIosAt size={size} />}
              >
                {parsedSocialMedia}
              </AboutItem>
            </>
          ) : null}
        </div>
      </div>
    );
  }
  return null;
};

export default ClubProfile;

const titles = {
  CLUB_NAME: "Club/Page Name",
  CLUB_DESCRIPTION: "Club/Page Description",
  CLUB_PAYMENT: "Club's Payment Link",
  YEAR_ESTABLISHED: "Year Established",
  LEADERSHIPS: "Leadership Team",
  CONTACT_INFO: "Contact Info",
  SOCIAL_MEDIA: "Social Media",
};

const icons: any = {
  phone: FaPhone,
  email: FaEnvelope,
  fb: FaFacebook,
  twitter: FaTwitter,
  youtube: FaYoutube,
  snapchat: FaSnapchat,
  website: FaGlobeAmericas,
  instagram: FaInstagram,
};

//   social_media: {
//     title: STRINGS.CLUB_ABOUT_TITLES.SOCIAL_MEDIA,
//     icon: { icon_type: ICON_TYPES.IconIonicons, icon_name: IONICONS_SOCIAL },
//     type: "json",
//     Component: typeObjectComponent,
//     items: {
//       facebook: {
//         title: STRINGS.SOCIAL_MEDIA_TITLES.FACEBOOK,
//         separator: "/ ",
//         icon: {
//           icon_type: ICON_TYPES.FontAwesome,
//           icon_name: "facebook",
//         },
//         linkFunc: handleFacebookPress,
//         autoCapitalize: "none",
//         inputProps: {
//           autoCapitalize: "none",
//           placeholder: STRINGS.SOCIAL_MEDIA_PLACEHOLDERS.FACEBOOK,
//         },
//       },
//       instagram: {
//         title: STRINGS.SOCIAL_MEDIA_TITLES.INSTAGRAM,
//         separator: "@ ",
//         link: "https://instagram.com/",
//         linkFunc: handleInstagramPress,
//         autoCapitalize: "none",
//         icon: {
//           icon_type: ICON_TYPES.FontAwesome,
//           icon_name: "instagram",
//         },
//         inputProps: {
//           autoCapitalize: "none",
//           placeholder: STRINGS.SOCIAL_MEDIA_PLACEHOLDERS.INSTAGRAM,
//         },
//       },
//       twitter: {
//         title: STRINGS.SOCIAL_MEDIA_TITLES.TWITTER,
//         separator: "@ ",
//         link: "https://twitter.com/",
//         linkFunc: handleTwitterPress,
//         autoCapitalize: "none",
//         icon: {
//           icon_type: ICON_TYPES.FontAwesome,
//           icon_name: "twitter",
//         },
//         inputProps: {
//           autoCapitalize: "none",
//           placeholder: STRINGS.SOCIAL_MEDIA_PLACEHOLDERS.TWITTER,
//         },
//       },
//       youtube: {
//         title: STRINGS.SOCIAL_MEDIA_TITLES.YOUTUBE,
//         separator: "@ ",
//         link: "https://youtube.com/",
//         linkFunc: handleYoutubePress,
//         autoCapitalize: "none",
//         icon: {
//           icon_type: ICON_TYPES.FontAwesome,
//           icon_name: "youtube",
//         },
//         inputProps: {
//           autoCapitalize: "none",
//           placeholder: STRINGS.SOCIAL_MEDIA_PLACEHOLDERS.YOUTUBE,
//         },
//       },
//       snapchat: {
//         title: STRINGS.SOCIAL_MEDIA_TITLES.SNAPCHAT,
//         separator: "@ ",
//         link: "https://snapchat.com/add/",
//         linkFunc: handleSnapChatPress,
//         autoCapitalize: "none",
//         icon: {
//           icon_type: ICON_TYPES.FontAwesome,
//           icon_name: "snapchat",
//         },
//         inputProps: {
//           autoCapitalize: "none",
//           placeholder: STRINGS.SOCIAL_MEDIA_PLACEHOLDERS.SNAPCHAT,
//         },
//       },
//       website: {
//         title: STRINGS.SOCIAL_MEDIA_TITLES.WEBSITE,
//         separator: ": ",
//         link: "https://",
//         autoCapitalize: "none",
//         linkFunc: handleUrlPress,
//         icon: {
//           icon_type: ICON_TYPES.FontAwesome,
//           icon_name: "globe",
//         },
//         inputProps: {
//           name: "WebSite",
//           keyboardType: "url", // iOS only
//           maxLength: INPUTS_MAX_LENGTH.website,
//           autoCapitalize: "none",
//           placeholder: STRINGS.SOCIAL_MEDIA_PLACEHOLDERS.WEBSITE,
//         },
//       },
//     },
//   },
// };
