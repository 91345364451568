import React from "react";
import get from "lodash.get";
import GeneralAction from "components/General/GeneralAction";

const HorizontalBadges = (props: any) => {
  const badges = get(props, "badges", []);
  const height = get(props, "height", "7vh");
  let mappedBadges: Array<any> = [];
  if (badges && Array.isArray(badges)) {
    const badgesCount = badges.length;
    mappedBadges = badges.map((e: any, i: number) => {
      let position = (i / badgesCount) * 100;
      return (
        <img
          key={i}
          style={{
            left: `${position}%`,
            position: "absolute",
            height: "100%",
            zIndex: badgesCount - i,
          }}
          src={e.logo}
          alt={"Badge"}
          onClick={GeneralAction}
        ></img>
      );
    });
  }
  return (
    <div className="hContainer">
      <div
        className="hContainer badgeContainer relative"
        style={{
          minWidth: height,
          height: height,
        }}
      >
        {mappedBadges}
      </div>
      <div
        style={{
          minWidth: height,
          height: height,
        }}
      ></div>
    </div>
  );
};

export default HorizontalBadges;
