import gql from "graphql-tag";

export const standardFollowing = gql`
  fragment FollowingFrag on Following {
    __typename
    following_id
    follower_id
    followed_id
    follower {
      __typename
      driver_id
      username
      default_image
    }
    followed {
      __typename
      driver_id
      username
      default_image
    }
    status_id
    status {
      __typename
      invite_status_id
      name
    }
  }
`;
export const createFollowingFragment = gql`
  fragment createFollowingFragment on Following {
    __typename
    following_id
    follower_id
    followed_id
    status_id
    status {
      __typename
      invite_status_id
      name
    }
  }
`;

export const followingRelationFragment = gql`
  fragment followingRelationFragment on Driver {
    __typename
    follows_me {
      ...createFollowingFragment
    }
    i_follow {
      ...createFollowingFragment
    }
  }
  ${createFollowingFragment}
`;
