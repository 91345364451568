import React from "react";
import get from "lodash.get";
import {
  vehicleToString,
  getVehicleMake,
  getVehicleClass,
} from "utils/vehicleUtils";
import VehicleName from "components/Vehicle/VehicleName";
import PufIcon from "components/General/PufIcons";

const VehicleDescriptionBox = (props: any) => {
  const vehicle = get(props, "vehicle", null);
  if (vehicle) {
    const vClass: any = getVehicleClass(vehicle);
    const vMake: any = getVehicleMake(vehicle);
    const vehicleString = vehicleToString(vehicle);
    return (
      <div className="vContainer alignCenter">
        <div className="hSimpleContainer alignCenter centerJustify">
          <img
            style={{ height: "10vw", width: "10vw", paddingRight: 10 }}
            src={vMake.logo}
          ></img>
          <VehicleName vehicle={vehicle}></VehicleName>
        </div>
        <div style={{ height: "5vw" }}></div>
        <div
          className="hSimpleContainer alignCenter centerJustify"
          style={{ color: vClass.color }}
        >
          <PufIcon icon={"puf-icon"} size={40} style={{ paddingRight: 10 }} />
          <span>{vClass.name}</span>
        </div>
      </div>
    );
  }
  return null;
};

export default VehicleDescriptionBox;
