import React from "react";
// @ts-ignore
import Linkify from "react-linkify";
import get from "lodash.get";

const EventDescription = (props: any) => {
  const description = get(props, "description", "");
  console.log({ description });
  const parsedDescription = description
    .split("\n")
    .map((item: any, i: number) => (
      <div key={i} style={{ textAlign: "left" }}>
        {item}
        <br />
      </div>
    ));
  if (description) {
    return (
      <div className="vContainer" style={{ width: "90%", alignSelf: "center" }}>
        <Linkify>{parsedDescription}</Linkify>;
      </div>
    );
  }
  return null;
};

export default EventDescription;
