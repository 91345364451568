import React from "react";
import get from "lodash.get";
import { FaShareSquare, FaShare } from "react-icons/fa";
import PufIcon from "./PufIcons";
import ActionHOC from "./ActionHOC";

const Share = (props: any) => {
  const text = get(props, "text", "");
  const square = get(props, "square", false);
  const onClick = get(props, "onClick", () => {});
  const Icon = square ? (
    <FaShareSquare className="smallPaddingRight" />
  ) : (
    <FaShare className="smallPaddingRight" />
  );
  return (
    <div style={{ fontSize: 12 }} onClick={onClick}>
      {Icon}
      {text}
    </div>
  );
};

export default ActionHOC(Share);
