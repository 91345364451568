import gql from "graphql-tag";
import { standardFollowing } from "graphql/fragments/followingFragments";
import { vehicleFragment } from "graphql/fragments/vehicleFragments";
import { classFragment } from "./classFragments";
import { cityFragment } from "./cityFragments";

export const GARAGE_FRAGMENT = gql`
  fragment garage on Driver {
    garage {
      __typename
      ...vehicleFrag
    }
  }
  ${vehicleFragment}
`;

export const driverFragment = gql`
  fragment driverFragment on Driver {
    __typename
    default_image
    username
    driver_id
    tagline
    verified
    user_id
    public_garage
    city_id
    default_vehicle
  }
`;

export const driverEditFragment = gql`
  fragment driverEditFrag on Driver {
    __typename
    driver_id
    username
    tagline
    location
    facebook
    instagram
    snapchat
    twitter
    youtube
    website
    bio
    past_cars
    dream_cars
    fav_brands
    public_garage
    city_id
    city {
      ...cityFragment
    }
  }
  ${cityFragment}
`;

export const driverProfileFragment = gql`
  fragment profileFrag on Driver {
    __typename
    default_image
    username
    bio
  }
`;

export const driverStatsFragment = gql`
  fragment driverStats on Driver {
    __typename
    stats {
      __typename
      countCrew
      countCars
      countClubs
      countFollowers
      countFollowings
      pendingInvitations
      countPosts
      countReservations
      classes {
        ...classFrag
      }
    }
  }
  ${classFragment}
`;

export const driverRelationFragment = gql`
  fragment driverRelationFrag on Driver {
    __typename
    follows_me {
      ...FollowingFrag
    }
    i_follow {
      ...FollowingFrag
    }
  }
  ${standardFollowing}
`;

export const driverVehicleInfo = gql`
  fragment driverVehicleInfo on Driver {
    default_vehicle
    ...garage
  }
  ${GARAGE_FRAGMENT}
`;

export const fullDriverFragment = gql`
  fragment fullDriverFragment on Driver {
    __typename
    default_image
    username
    user_id
    driver_id
    tagline
    location
    location_conf
    confirmed
    instagram
    facebook
    snapchat
    twitter
    website
    youtube
    bio
    past_cars
    fav_brands
    dream_cars
    public_garage
    i_follow {
      __typename
      following_id
      status {
        name
      }
    }
    city_id
    city {
      ...cityFragment
    }
    ...driverStats
    ...garage
    verified
    default_vehicle
  }
  ${GARAGE_FRAGMENT}
  ${cityFragment}
  ${driverStatsFragment}
`;
