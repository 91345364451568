import React from "react";
import get from "lodash.get";
const EventImageBox = (props: any) => {
  const uri = get(props, "uri", null);
  const p = get(props, "public", null);
  const official = get(props, "official", null);
  if (uri) {
    return (
      <img
        src={uri}
        style={{
          width: "100vw",
          height: "100vw",
          maxHeight: "100vh",
          objectFit: "contain",
        }}
      ></img>
    );
  }
  return null;
};

export default EventImageBox;
