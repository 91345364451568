const mockEvent = {
  __typename: "Event",
  venue_event_id: "648",
  name: "Sub-Class Test Event!!",
  default_image:
    "https://elasticbeanstalk-us-west-2-454948944021.s3.us-west-2.amazonaws.com/development/carClub/38/event/648/xMWuW5fDIKXtLwBeRmFczftQfdWMC7Oj9iW2tS83.jpeg",
  address: "Teghebebsjs d dishes dens Kendall \n",
  cost: 100,
  city_id: 13,
  official: false,
  public: false,
  public_cc_event: true,
  private_cc_event: false,
  available_spots: 0,
  spots: 0,
  unlimited_spots: true,
  start_time: "2020-08-20 21:35:40",
  end_time: "2020-08-20 22:35:00",
  allowed_classes: [],
  allowed_sub_classes: [2, 3, 4],
  restrict_makes: true,
  code: null,
  active: true,
  car_club: {
    __typename: "CarClub",
    name: "Best Club",
    car_club_id: "38",
  },
  active_reservations_count: 3,
  requires_signup_code: false,
  requires_payment: false,
  max_driver_guests: 0,
  guests: 0,
  guest_price: 0,
  allow_guests: true,
  web_page: "www.tesla.com",
  sign_up_page: "Tesla.com",
  summary: `http://parkupfront.com
  alkjhdsfajk asdf asdf We’ve just received approval from the  City of Southlake to go ahead and host the event!
  Same rules as last time, masks on, practice social distancing, and do not come if you feel ill.
  SEE YOU THERE!!!
  .
  JULY 25TH 
  8-10AM
  1900 W. Kirkwood Boulevard, Southlake, TX
  Admission fee to benefit @southlakecentenniallionsclub 
  DISCLAIMER: NO REFUNDS!
  http://parkupfront.com
  http://parkupfront.com
The North Texas Rally is doing an all Buc-ees Visit around Dallas Texas.

We will be visiting cities such as Terrell, Royse City, Melissa, Sanger, Denton and Justin where all Buc-ees are available.

This Rally is a mix of highway and also back roads around Dallas including route 455.

Once registered full details will be given.

Total amount of driving is 4 hours.

We have 3 packages based off the minimum of $50 you have paid.

Basic-$60 (This is for no photos +unlimited passengers+window stickers+shirt)

Intermediate- $80 (this is for 5 photos +unlimited passengers+window stickers+shirt)

Advance-$100 (this is for 10 professional photos+unlimited passengers+window stickers+shirt)

Also don’t forget that what you also pay included with the packages is going to St.Jude.

Thank you for being part of this and thank you for being part of a cause.

214-713-5744
@iflygreat on Instagram
For any additional info

I have 35 spots out of Dallas
10 out of Austin
10 out of Houston`,
  payment_type: {
    payment_type_id: "1",
    type: "in_app",
    description: "Pay directly through the app (10% processing fee)",
    __typename: "PaymentType",
  },
  payment_type_id: 1,
  unlimited_guests: true,
  owner_role: null,
  sponsors: ["Tesla.com", "Hahaha ajajajs\nJajsjsjsj"],
};

export default mockEvent;
