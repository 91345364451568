import gql from "graphql-tag";
import {
  clubInfoFragment,
  clubCountFragment,
  clubRoleFragment,
} from "graphql/fragments/clubFragments";
import { userFragment } from "graphql/fragments/userFragments";
import { driverFragment } from "graphql/fragments/driverFragments";

export const getCarClub = gql`
  query anonymusCarClub($car_club_id: Int) {
    anonymusCarClub(car_club_id: $car_club_id) {
      msg
      code
      data {
        ... on CarClub {
          ...clubInfoFrag
          ...clubCountFrag
          ...clubRoleFrag
          owner {
            ...userFragment
            driver {
              ...driverFragment
            }
          }
        }
      }
    }
  }
  ${clubInfoFragment}
  ${clubCountFragment}
  ${clubRoleFragment}
  ${userFragment}
  ${driverFragment}
`;
