const defaultDataId: any = {
  Post: { id: "post_id", type: "post" },
  Ad: { id: "ad_id", type: "ad" },
  City: { id: "city_id", type: "city" },
  Card: { id: "card_id", type: "card" },
  CarClub: {
    id: "car_club_id",
    type: "carClub",
    linkText: (XXX: string) => {
      return `Join ${XXX} on the ParkUpFront App!`;
    },
    shareTextId: "name",
    shareType: "club",
  },
  Conversation: { id: "conversation_id", type: "conversation" },
  Classification: { id: "vehicle_classification_id", type: "classification" },
  Class: { id: "vehicle_class_id", type: "vehicleClass" },
  Customer: { id: "customer_id", type: "customer" },
  Driver: {
    id: "driver_id",
    type: "driver",
    linkText: (XXX: string) => {
      return `Follow ${XXX} on the ParkUpFront App!`;
    },
    shareTextId: "username",
    shareType: "garage",
  },
  Event: {
    id: "venue_event_id",
    type: "event",
    linkText: (XXX: string) => {
      return `Register for ${XXX} here!`;
    },
    shareTextId: "name",
    shareType: "event",
  },
  Following: { id: "following_id", type: "following" },
  Like: { id: "like_id", type: "like" },
  Listener: { id: "listener_id", type: "listener" },
  Manufacturer: { id: "vehicle_manufacturer_id", type: "make" },
  Message: { id: "message_id", type: "message" },
  Notification: { id: "notification_id", type: "notification" },
  PolyLike: { id: "polymorphic_like_id", type: "polylike" },
  State: { id: "state_id", type: "state" },
  VehiclePhoto: { id: "vehicle_photo_id", type: "vehiclePhoto" },
  Vehicle: { id: "driver_vehicle_id", type: "vehicle" },
  EventReservation: { id: "event_reservation_id", type: "eventReservation" },
  PushDevice: { id: "push_device_id", type: "pushDevice" },
  ClubRole: { id: "car_club_role_id", type: "clubRole" },
  PaymentType: { id: "payment_type_id", type: "paymentType" },
  Video: { id: "video_id", type: "video" },
  SplashScreen: { id: "splash_screen_id", type: "splash" },
  AppVersion: { id: "app_version_id", type: "version" },
  Photo: { id: "photo_id", type: "photo" },
};
export default defaultDataId;
