import React from "react";
import get from "lodash.get";

const AboutItem = (props: any) => {
  const icon = get(props, "icon", null);
  const iconSize = get(props, "iconSize", 30);
  const title = get(props, "title", "");
  const children = get(props, "children", null);
  console.log({ props });
  if (title || icon) {
    return (
      <div className="hContainer">
        <div style={{ flex: 1 }}>{icon}</div>
        <div
          style={{
            flex: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <div className="fontSizeBig SFBold">{title}</div>
          {children}
        </div>
      </div>
    );
  }
  return null;
};

export default AboutItem;
