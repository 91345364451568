import React, { useState } from "react";
import get from "lodash.get";
import { GeneralInfo, LinkInfo } from "components/General/InfoComponents";
import RoundYellowButton from "components/General/RoundYellowButton";
import mockEvent from "utils/mockEvent";
import { getEventPaymentType } from "utils/eventUtils";
import PufIcon from "components/General/PufIcons";
import { completeWeekday_MonthDayHrMnFormat } from "utils/time";
import { FaChevronDown } from "react-icons/fa";

const InfoContainer = (props: any) => {
  const event = get(props, "event", mockEvent);
  console.log({ event });
  const eventPaymentType = get(event, "payment_type.type");
  const paymentType: any = getEventPaymentType(eventPaymentType);
  const sponsors = get(event, "sponsors", []);

  return (
    <div className="hContainer SFBold fontSize">
      <div
        style={{ paddingLeft: "20%", paddingRight: "20%", textAlign: "start" }}
      >
        <div className="fontSizeBig">{event.name}</div>
        <div className="space" />
        <GeneralInfo
          text={event.car_club.name}
          icon={
            <PufIcon
              icon={"Club-Icon"}
              size={14}
              set1={0}
              className="smallPaddingRight"
            />
          }
        ></GeneralInfo>
        <div className="smallSpace" />
        <LinkInfo
          uri={event.web_page}
          text={"Website"}
          icon={
            <PufIcon icon={"world"} size={14} className="smallPaddingRight" />
          }
        ></LinkInfo>
        <div className="smallSpace" />
        <Sponsors sponsors={sponsors} />
        <div className="smallSpace" />
        <div className="hContainer alignCenter yellow">
          <GeneralInfo
            text={
              event.cost ? "Ticket Price: $" + event.cost : "Ticket Price: Free"
            }
          ></GeneralInfo>
          <div className="hSmallSpace" />
          <RoundYellowButton text={paymentType} />
        </div>
        <div className="smallSpace" />
        <div className="yellow">
          <GeneralInfo
            text={"Attendees: " + event.active_reservations_count}
          ></GeneralInfo>
        </div>
        <div className="smallSpace" />
        <GeneralInfo
          text={
            "Starts: " + completeWeekday_MonthDayHrMnFormat(event.start_time)
          }
        ></GeneralInfo>
        <div className="smallSpace" />
        <GeneralInfo
          text={"Ends: " + completeWeekday_MonthDayHrMnFormat(event.end_time)}
        ></GeneralInfo>
        <div className="smallSpace" />
        <GeneralInfo
          text={event.address}
          icon={
            <PufIcon
              set1={0}
              icon="Direction-Icon"
              size={14}
              className="smallPaddingRight"
            />
          }
        ></GeneralInfo>
      </div>
    </div>
  );
};

const Sponsors = (props: any) => {
  const sponsors = get(props, "sponsors", []);
  const [showSponsors, setShowSponsors] = useState(false);
  let mappedSponsors = null;
  let hasSponsors = false;
  if (sponsors && Array.isArray(sponsors) && sponsors.length > 0) {
    hasSponsors = true;
    mappedSponsors = sponsors.map((e, index) => {
      return <GeneralInfo key={index} text={e}></GeneralInfo>;
    });
  }
  if (hasSponsors) {
    return (
      <div>
        <div
          onClick={() => {
            setShowSponsors(!showSponsors);
          }}
        >
          <GeneralInfo
            text={"Sponsors"}
            icon={<FaChevronDown size={14} className="smallPaddingRight" />}
          ></GeneralInfo>
        </div>
        {showSponsors ? <div className="sponsors">{mappedSponsors}</div> : null}
      </div>
    );
  }
  return null;
};

export default InfoContainer;
