import React from "react";
import get from "lodash.get";
import ActionHOC from "./ActionHOC";

export const Tab = (props: any) => {
  const active = get(props, "active", false);
  const text = get(props, "text", "");
  const style = active ? "activeTab" : "tab";
  return <div className={style}>{text}</div>;
};

export const Tabs = ActionHOC((props: any) => {
  const tabs = get(props, "tabs", []);
  const onClick = get(props, "onClick", () => {});
  let activeTab = get(props, "active", 0);
  if (tabs && Array.isArray(tabs) && tabs.length > 0) {
    if (activeTab > tabs.length) {
      activeTab = tabs.length - 1;
    }
    const parsedTabs = tabs.map((e, index) => {
      return <Tab key={index} active={index == activeTab} text={e} />;
    });

    return (
      <div className={"hContainer spaceEvenly"} onClick={onClick}>
        {parsedTabs}
      </div>
    );
  }
  return null;
});
