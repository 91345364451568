export const eventPaymentTypesConstants: any = {
  in_app: {
    key: "in_app",
    short_description: "Card",
    total_description: "- Due now",
    long_description:
      "This event allows you to pay directly through the app when you click “Register.” Once you’ve registered and paid, your Reservation Page will serve as your ticket at the event.",
  },
  third_party: {
    key: "third_party",
    total_description: "- Due on third party website",
    short_description: "Third Party",
    long_description:
      "This event requires payment and registration on a third party site. Click “Plan to Attend” to let your PUF followers know you are planning on going to this event.",
  },
  cash: {
    key: "cash",
    short_description: "Cash",
    total_description: "- Cash due at event",
    long_description:
      "This event will collect cash at the entrance. Check event description for pricing details.",
  },
};

export const getEventPaymentType = (eventPaymentType: string) => {
  const tempPaymentType = eventPaymentTypesConstants.hasOwnProperty(
    eventPaymentType
  )
    ? eventPaymentTypesConstants[eventPaymentType].short_description
    : null;
  return tempPaymentType;
};
