import moment from "moment-timezone";

export const MIN_YEAR = 1900;
export const DB_DATE_FORMAT = "YYYY MMM DD";
export const DB_TIME_FORMAT = "hh:mm A";
export const DB_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const PRETTY_DAY_FORMAT = "ddd, MMM D";
export const PRETTY_DATETIME_FORMAT = "ddd, MMM D h:mm A";
export const KYLE_EVENT_DATETIME_FORMAT = "dddd, MMM D h:mm A";
export const PICKER_DATETIME_FORMAT = "YYYY ddd, MMM D h:mm A";
export const PRETTY_DATETIME_FORMAT_LONG = "dddd, MMM D, h:mm A";
export const PRETTY_TIME_FORMAT = "h:mm A";
export const DB_TIME_ZONE = "America/Chicago";

export function completeWeekday_MonthDayHrMnFormat(date: string): string {
  // Example: Saturday, Apr 13 2:00 PM
  let formattedDate = moment
    .tz(date, DB_DATETIME_FORMAT, DB_TIME_ZONE)
    .local()
    .format(KYLE_EVENT_DATETIME_FORMAT);
  return formattedDate;
}
