import gql from "graphql-tag";
import {
  clubInfoFragment,
  clubCountFragment,
  clubRoleFragment
} from "./clubFragments";
import { eventFragmentForFeed } from "./eventFragments";

export const postFullFragment = gql`
  fragment postFullFragment on Post {
    __typename
    post_id
    title
    text
    liked
    likes_count
    comments_count
    image
    created_at
    resource_type
    user_id
    feed_id
    feed_type
    publisher_id
    publisher_type
    publisher {
      __typename
      ... on Driver {
        default_image
        username
        verified
        driver_id
      }
      ... on CarClub {
        car_club_id
        default_image
        name
        public
        active
        members_count
      }
    }
    resource {
      __typename
      ... on Photo {
        photo_id
        caption
        public_url
      }
      ... on Event {
        __typename
        ...eventFragForFeed
      }
      ... on Vehicle {
        default_image
        vehicle_color_id
        driver_vehicle_id
        year
        model {
          name
          vehicle_model_id
          classification {
            vehicle_classification_id
            name
            manufacturer {
              name
              vehicle_manufacturer_id
              logo
            }
            vehicle_class {
              vehicle_class_id
              name
              badge
              logo
              color
            }
          }
        }
      }
      ... on CarClub {
        ...clubInfoFrag
        ...clubCountFrag
        ...clubRoleFrag
      }
      ... on Video {
        __typename
        video_id
        full_video_url
        full_video_path
        stream_path
        stream_url
        thumbnail
      }
    }
  }
  ${clubInfoFragment}
  ${clubCountFragment}
  ${clubRoleFragment}
  ${eventFragmentForFeed}
`;

export const postFragment = gql`
  fragment postBasicFragment on Post {
    __typename
    post_id
  }
`;

export const postCommentsFragment = gql`
  fragment postCommentsFragment on Post {
    last_comment {
      ...postFullFragment
      last_comment {
        ...postFullFragment
      }
      first_comments {
        ...postFullFragment
      }
      replies_count
    }
    first_comments {
      ...postFullFragment
      first_comments {
        ...postFullFragment
      }
      last_comment {
        ...postFullFragment
      }
      replies_count
    }
    comments_count
    replies_count
  }
  ${postFullFragment}
`;
export const adCommentsFragment = gql`
  fragment adCommentsFragment on Ad {
    comments_count
  }
`;
export const postCommentFullFragment = gql`
  fragment postCommentFullFragment on Post {
    ...postFullFragment
    ...postCommentsFragment
  }
  ${postFullFragment}
  ${postCommentsFragment}
`;

export const feedFragment = gql`
  fragment feedFrag on PaginatedData {
    __typename
    ... on Ad {
      ad_id
      liked
      likes_count
      ...adCommentsFragment
      image
      call_to_action_url
      call_to_action_label
      call_to_action_background_color
      call_to_action_font_color
      text
      publisher {
        ... on Sponsor {
          __typename
          sponsor_id
          sponsor_name
          default_image
        }
      }
    }
    ... on Post {
      ...postFullFragment
      ...postCommentsFragment
    }
  }
  ${postFullFragment}
  ${postCommentsFragment}
  ${adCommentsFragment}
`;
export const socialPostFragment = gql`
  fragment socialPostFragment on Post {
    __typename
    post_id
    liked
    likes_count
    comments_count
  }
`;
