import React from "react";
import get from "lodash.get";
import { getVehicleClass } from "utils/vehicleUtils";

const VehicleImageBox = (props: any) => {
  const vehicle = get(props, "vehicle", {});

  if (vehicle && vehicle.default_image) {
    const vClass: any = getVehicleClass(vehicle);
    const color = vClass.color;
    console.log(color);
    return (
      <img
        style={{
          height: "100%",
          width: "100%",
          objectFit: "contain",
          borderRadius: "15%",
          borderStyle: "solid",
          borderWidth: 2,
          borderColor: color,
          backgroundColor: "black",
        }}
        src={vehicle.default_image}
      ></img>
    );
  }
  return null;
};

export default VehicleImageBox;
