import gql from "graphql-tag";
import {
  driverFragment,
  driverRelationFragment,
  driverStatsFragment,
  GARAGE_FRAGMENT,
  driverVehicleInfo,
} from "graphql/fragments/driverFragments";
import { cityFragment } from "graphql/fragments/cityFragments";
import {
  clubInfoFragment,
  clubCountFragment,
  clubRoleFragment,
} from "graphql/fragments/clubFragments";

// export const driverEditProfileFragment = gql`
//   fragment profileFrag on editProfile {
//     __typename
//     default_image
//     username
//     bio
//   }
// `;

export const editProfileQ = gql`
  query editProfile {
    editProfile @client {
      __typename
      driver_id
      default_image
      username
      bio
      tagline
      city_id
    }
  }
`;

export const driverSearch = gql`
  query driverSearch(
    $search: DriverSearchInput
    $page: Int
    $nextPageUrl: String
    $withStats: Boolean! = false
    $withGarage: Boolean! = false
  ) {
    driverSearch(search: $search, page: $page, nextPageUrl: $nextPageUrl) {
      next_page_url
      data {
        __typename
        ... on Driver {
          ...driverFragment
          ...driverRelationFrag
          ...driverStats @include(if: $withStats)
          ...garage @include(if: $withGarage)
        }
      }
    }
  }

  ${driverFragment}
  ${driverRelationFragment}
  ${driverStatsFragment}
  ${GARAGE_FRAGMENT}
`;

// ${driverEditProfileFragment}

export const resendDriverActivation = gql`
  query resendDriverActivation {
    resendDriverActivation {
      msg
      code
    }
  }
`;

export const getDriverQueryGarage = gql`
  query getDriver($driver_id: Int, $username: String) {
    driver(driver_id: $driver_id, username: $username) {
      code
      msg
      data {
        __typename
        ... on Driver {
          driver_id
          ...driverVehicleInfo
        }
      }
    }
  }
  ${driverVehicleInfo}
`;
export const getDriverQueryStats = gql`
  query getDriver($driver_id: Int, $username: String) {
    driver(driver_id: $driver_id, username: $username) {
      code
      msg
      data {
        __typename
        ... on Driver {
          driver_id
          ...driverStats
        }
      }
    }
  }
  ${driverStatsFragment}
`;
export const getDriverQueryStatsCity = gql`
  query getDriver($driver_id: Int) {
    anonymusDriver(driver_id: $driver_id) {
      code
      msg
      data {
        __typename
        ... on Driver {
          default_image
          username
          user_id
          driver_id
          verified
          tagline
          location
          ...driverVehicleInfo
          city {
            ...cityFragment
          }
          ...driverStats
        }
      }
    }
  }
  ${cityFragment}
  ${driverStatsFragment}
  ${driverVehicleInfo}
`;
export const getDriverMemberships = gql`
  query driverMemberships($driver_id: Int, $nextPageUrl: String) {
    driverMemberships(driver_id: $driver_id, nextPageUrl: $nextPageUrl) {
      msg
      code
      next_page_url
      data {
        ... on CarClub {
          ...clubInfoFrag
          ...clubCountFrag
          ...clubRoleFrag
        }
      }
    }
  }
  ${clubInfoFragment}
  ${clubCountFragment}
  ${clubRoleFragment}
`;
