import React from "react";
import get from "lodash.get";
import ActionHOC from "components/General/ActionHOC";

const GeneralInfoComp = (props: any) => {
  const text = get(props, "text", "");
  const Icon = get(props, "icon", null);
  const onClick = get(props, "onClick", null);

  if (text) {
    return (
      <div className="hSimpleContainer " onClick={onClick}>
        {Icon}
        {"  " + text}
      </div>
    );
  }
  return null;
};
const GeneralInfo = ActionHOC(GeneralInfoComp);

const LinkInfo = (props: any) => {
  const text = get(props, "text", "");
  let uri = get(props, "uri", "");
  const icon = get(props, "icon", null);
  if (uri && text) {
    if (!uri.includes("https://") || !uri.includes("https://")) {
      uri = "https://" + uri;
    }
    return (
      <div
        onClick={() => {
          window.open(uri, "_blank");
        }}
        className="hSimpleContainer "
      >
        {icon}
        {text}
      </div>
    );
  }
  return null;
};

export { GeneralInfo, LinkInfo };
