import gql from "graphql-tag";

export const stateFragment = gql`
  fragment stateFragment on State {
    state_id
    name
    abbreviation
  }
`;

export const cityFragment = gql`
  fragment cityFragment on City {
    city_id
    name
    state_id
    state {
      ...stateFragment
    }
  }
  ${stateFragment}
`;
