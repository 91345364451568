import React from "react";
// @ts-ignore
import IcoMoon, { iconList } from "react-icomoon";
import iconSet from "assets/fonts/puficonsSelection.json";
import iconSet2 from "assets/fonts/pufSelection.json";
import get from "lodash.get";

console.log({ icons: iconList(iconSet) });
console.log({ icons: iconList(iconSet2) });
const PufIcon = (props: any) => {
  const set1 = get(props, "set1", true);
  return <IcoMoon iconSet={set1 ? iconSet : iconSet2} {...props} />;
};

export default PufIcon;
