import gql from "graphql-tag";

export const clubInfoFragment = gql`
  fragment clubInfoFrag on CarClub {
    __typename
    car_club_id
    default_image
    city_id
    city {
      city_id
      name
      state {
        state_id
        name
        abbreviation
      }
      state_id
    }
    name
    public
    active
    local
    membership_fee
    description
    year_established
    lead_team
    contact_info
    social_media
    payment_link
  }
`;
export const clubCountFragment = gql`
  fragment clubCountFrag on CarClub {
    membersonly_count
    members_count
    feed_count
    events_count
  }
`;

export const clubRoleFragment = gql`
  fragment clubRoleFrag on CarClub {
    my_role {
      car_club_member_id
      member_id
      joined
      role {
        car_club_role_id
        car_club_role
      }
    }
  }
`;
