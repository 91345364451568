import React from "react";

import GeneralAction from "components/General/GeneralAction";

const ActionHOC = (Comp: any) => {
  return (props: any) => {
    return <Comp {...props} onClick={GeneralAction}></Comp>;
  };
};

export default ActionHOC;
