import gql from "graphql-tag";
import { classificationFragment } from "graphql/fragments/classificationFragments";

export const modelFragment = gql`
  fragment modelFrag on Model {
    __typename
    vehicle_model_id
    name
    year_start
    year_end
    vehicle_classification_id
    is_active
    created_at
    updated_at
    classification {
      ...classificationFrag
    }
  }
  ${classificationFragment}
`;
