import React from "react";

import EventStripe from "components/Event/EventStripe";
import EventImageBox from "components/Event/EventImageBox";
import EventDescription from "components/Event/Description";
import HorizontalBadges from "components/Badges/HorizontalBadges";
import InfoContainer from "components/Event/EventInfoContainer";
import Register from "components/Event/Register";
import EventBadges from "components/Event/EventBadges";

import "styles/pages-styles/eventProfile.scss";
import "styles/generals.scss";
import { useQuery } from "@apollo/client";
import { GET_EVENT } from "graphql/queries/eventQueries";
import get from "lodash.get";
import { useParams } from "react-router-dom";
import Share from "components/General/Share";
import Attendees from "components/Event/Attendees";

const EventProfile = (props: any) => {
  const params = useParams();
  const eventId = Number.parseInt(get(params, "eventId", -1));
  const { data, loading, error } = useQuery(GET_EVENT, {
    fetchPolicy: "no-cache",
    variables: { venue_event_id: eventId },
  });
  if (loading) {
    return null;
  }
  const event = get(data, "anonymusEvent", {});

  return (
    <div className="vContainer">
      <EventStripe name={event.name} official={event.official} />
      <EventImageBox uri={event.default_image} />
      <div className="space" />
      <div className="hContainer flexCenter">
        <div className="hSimpleContainer flexSimpleMargin">
          <div className=" badgesContainer selfJustifyStart">
            <EventBadges eventId={event.venue_event_id} />
          </div>
          <div className="hContainer flexEnd">
            <div className="hSimpleContainer">
              <div className="vContainer spaceEvenly alignStart">
                <Share text={"SHARE EVENT LINK"} square={1} />
                <Share text={"SHARE IN CRUISEFEED"} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="space" />
      <InfoContainer event={event} />
      <div className="space" />
      <Attendees eventId={eventId} />
      <div className="space" />
      <Register />
      <div className="space" />
      <EventDescription description={event.summary} />
    </div>
  );
};

export default EventProfile;
