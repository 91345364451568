import { isAndroid, isIOS } from "react-device-detect";
const GeneralAction = () => {
  if (window.confirm("Download the ParkUpFront App to continue!")) {
    let store = "https://apps.apple.com/us/app/parkupfront/id1207072194";
    if (isIOS) {
      store = "https://apps.apple.com/us/app/parkupfront/id1207072194";
    }
    if (isAndroid) {
      store =
        "https://play.google.com/store/apps/details?id=com.parkupfrontappv0&hl=en";
    }
    console.log({ isIOS, isAndroid, store });
    if (store) {
      window.open(store);
    }
  }
};

export default GeneralAction;
