import React from "react";
import BackgroundImage from "assets/images/puf_garage.jpeg";
import get from "lodash.get";
import VehicleImageBox from "components/Vehicle/VehicleImageBox";
import VehicleDescriptionBox from "components/Vehicle/VehicleDescriptionBox";
import GeneralAction from "components/General/GeneralAction";

const Garage = (props: any) => {
  const vehicles = get(props, "garage", []);
  const defaultV = get(props, "default", -1);
  if (vehicles && Array.isArray(vehicles) && vehicles.length > 0) {
    let vehicle = vehicles.find((v) => {
      return v.driver_vehicle_id == defaultV;
    });
    console.log({ vehicle, defaultV });
    return (
      <div>
        <div className="hContainer flexCenter">
          <div
            style={{
              width: "100vmin",
              height: "100vmin",
              backgroundImage: `url(${BackgroundImage})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{ width: "72vmin", height: "72vmin" }}
              onClick={GeneralAction}
            >
              <VehicleImageBox vehicle={vehicle} />
            </div>
          </div>
        </div>
        <div style={{ height: "5vw" }}></div>
        <div className="hContainer ">
          <VehicleDescriptionBox vehicle={vehicle} />
        </div>
        <div style={{ height: "10vw" }}></div>
      </div>
    );
  }
  return null;
};
export default Garage;
