import get from "lodash.get";

export function vehicleToString(car: any) {
  let result = "";
  let makePath = "model.classification.manufacturer.name";
  let modelPath = "model.classification.name";
  let carYear = get(car, "year");
  let carMake = get(car, makePath);
  let carModel = get(car, modelPath);
  if (!car) {
    return result;
  }
  if (car.year) {
    result = `${result} ${carYear} `;
  }
  if (carMake) {
    result = `${result} ${carMake} `;
  }
  if (carModel) {
    result = `${result}- ${carModel} `;
  }
  return result;
}
export function getVehicleClass(car: any) {
  let result = {};
  let path = "model.classification.vehicle_class";
  result = get(car, path, {});
  return result;
}
export function getVehicleMake(car: any) {
  let result = {};
  let path = "model.classification.manufacturer";
  result = get(car, path, {});
  return result;
}
