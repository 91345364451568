import {
  InMemoryCache,
  ApolloClient,
  defaultDataIdFromObject,
} from "@apollo/client";
import defaultDataId from "../utils/graphqlTypes";

const options = {
  dataIdFromObject: (object: any) => {
    if (object.__typename && defaultDataId[object.__typename]) {
      return (
        defaultDataId[object.__typename].type +
        "_" +
        object[defaultDataId[object.__typename].id]
      );
    }
    switch (object.__typename) {
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
};

const client = new ApolloClient({
  // ...other arguments...
  //uri: "https://graphql.dev.parkupfrontpartners.com/",
  uri: "https://gqlv2.parkupfrontpartners.com/",
  //uri: "http://localhost:4000/",
  cache: new InMemoryCache(options),
});

export default client;
