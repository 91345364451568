import React from "react";
import get from "lodash.get";

const VehicleName = (props: any) => {
  const vehicle = get(props, "vehicle", {});
  const classification = get(vehicle, "model.classification.name", "");
  const make = get(vehicle, "model.classification.manufacturer.name", "");
  const year = get(vehicle, "year", null);
  return (
    <div>
      <div>{year + " " + make}</div>
      <div>{classification}</div>
    </div>
  );

  return null;
};

export default VehicleName;
