import React from "react";
import get from "lodash.get";
import ActionHOC from "./ActionHOC";

const RoundYellowButton = (props: any) => {
  const text = get(props, "text", "");
  const onClick = get(props, "onClick", () => {});
  if (text) {
    return (
      <div
        className="fontSizeSmall yellowBorder flexCenter"
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          borderRadius: 10,
          paddingLeft: 5,
          paddingRight: 5,
        }}
        onClick={onClick}
      >
        {text}
      </div>
    );
  }
  return null;
};

export default ActionHOC(RoundYellowButton);
