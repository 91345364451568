import React from "react";
import get from "lodash.get";
import { FaTicketAlt } from "react-icons/fa";

import PufIcons from "../General/PufIcons";

const EventStripe = (props: any) => {
  const official = get(props, "official", null);
  //const pub = get(props, "public_cc_event", false);
  const priv = get(props, "private_cc_event", false);

  const className = official ? "officialEvent" : "publicEvent";

  const className2 =
    "hSimpleContainer flexStart smallPadding  alignCenter flexCenter black flexSimpleMargin";
  const text = official
    ? "OFFICIAL EVENT"
    : priv
    ? "PRIVATE CLUB EVENT"
    : "PUBLIC CLUB EVENT";

  return (
    <div className={"hContainer flexCenter fontSizeSmall " + className}>
      {!official ? (
        <div className={className2}>
          <FaTicketAlt size={15} className={"simplePaddingRight"} />
          {text}
        </div>
      ) : (
        <div className={className2}>
          <PufIcons icon={"crown"} size={20} className={"simplePaddingRight"} />
          {text}
        </div>
      )}
    </div>
  );
};

export default EventStripe;
