import React from "react";
import get from "lodash.get";
import ProfileImage from "components/General/ProfileImage";
import { GeneralInfo } from "components/General/InfoComponents";
import PufIcon from "components/General/PufIcons";
import ClubStats from "./ClubStats";
import Share from "components/General/Share";
import FollowButton from "components/Driver/FollowButton";
import RoundYellowButton from "components/General/RoundYellowButton";

const ClubRow = (props: any) => {
  const club = get(props, "club", {});
  const city = get(club, "city.name", "");
  const state = get(club, "city.state.abbreviation", "");

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
      <div style={{ height: "20vmin", width: "20vmin", padding: 10 }}>
        <ProfileImage src={club.default_image} />
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "space-evenly",
          textAlign: "left",
        }}
      >
        <div className="hContainer fontSizeBig SFBold alignCenter spaceBetween">
          {club.name}
          <div style={{ width: 100, textAlign: "center", paddingRight: 20 }}>
            <RoundYellowButton text="join" />
          </div>
        </div>
        <div className="fontSizeSmall hContainer spaceBetween alignCenter">
          <GeneralInfo
            text={city + " " + state}
            icon={
              <PufIcon
                icon="Location-Icon"
                size={12}
                set1={0}
                className="smallPaddingRight"
              />
            }
          />
          <div className="fontSizeSmall" style={{ paddingRight: 25 }}>
            <Share text={"SHARE CLUB"} />
          </div>
        </div>
        <div className="fontSizeSmall textCenter">
          <ClubStats club={club} />
        </div>
      </div>
    </div>
  );
};

export default ClubRow;
