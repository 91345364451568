import React from "react";
import get from "lodash.get";
import { VStat } from "components/General/Stat";
import PufIcon from "components/General/PufIcons";
import GeneralAction from "components/General/GeneralAction";
const DriverStats = (props: any) => {
  const stats = get(props, "stats", {});
  const statKeyAndLocations: any = {
    Followers: "countFollowers",
    Following: "countFollowings",
    Posts: "countPosts",
    Clubs: "countClubs",
  };
  const parsedStats = Object.keys(statKeyAndLocations).map((e, index) => {
    return (
      <VStat key={index} text={e} stat={stats[statKeyAndLocations[e]]}></VStat>
    );
  });
  parsedStats.push(
    <PufIcon
      key={-1}
      icon="Comment-Icon"
      size={25}
      color="white"
      set1={0}
      onClick={GeneralAction}
    />
  );
  return (
    <div className="hContainer spaceEvenly alignCenter">{parsedStats}</div>
  );
};

export default DriverStats;
