import React from "react";
import get from "lodash.get";
import ActionHOC from "./ActionHOC";

export const VStat = ActionHOC((props: any) => {
  const stat = get(props, "stat", 0);
  const statText = get(props, "text", 0);
  const onClick = get(props, "onClick", () => {});
  return (
    <div onClick={onClick}>
      <div>{stat}</div>
      <div>{statText}</div>
    </div>
  );
});
