import gql from "graphql-tag";
import { modelFragment } from "graphql/fragments/modelFragments";
import { socialPostFragment } from "./PostFragments";

export const vehicleFragment = gql`
  fragment vehicleFrag on Vehicle {
    __typename
    driver_vehicle_id
    year
    license_plate
    edition
    driver_id
    color
    vehicle_color_id
    vehicle_model_id
    active
    default_image
    owned_since
    story
    mods
    awards
    fav_events
    purchased_from
    transmission
    engine_type
    current_mods
    future_mods
    fav_shop
    vehicle_bio
    created_at
    updated_at
    model {
      ...modelFrag
    }
    first_post {
      ...socialPostFragment
    }
  }
  ${modelFragment}
  ${socialPostFragment}
`;
export const vehicleModelFragment = gql`
  fragment vehicleModelFragment on Vehicle {
    __typename
    model {
      ...modelFrag
    }
  }
  ${modelFragment}
`;
export const vehiclePhotoFragment = gql`
  fragment vehiclePhotoFrag on VehiclePhoto {
    __typename
    vehicle_photo_id
    full_size_url
    full_size_path
    driver_vehicle_id
    active
    deleted_at
  }
`;
export const vehicleWithPhotoFragment = gql`
  fragment vehicleWhitPhotoFrag on Vehicle {
    __typename
    photos {
      ...vehiclePhotoFrag
    }
  }
  ${vehiclePhotoFragment}
`;

export const updateDefaultVehiclePhoto = gql`
  fragment updateDefaultVehiclePhoto on Vehicle {
    __typename
    default_image
  }
`;
