import gql from "graphql-tag";

export const userFragment = gql`
  fragment userFragment on User {
    __typename
    user_id
    first_name
    last_name
    email
    password
    phone
    api_token
    last_login
    is_active
    rembember_token
    work_position_id
    role_id
    preferred
    created_at
    updated_at
    deleted_at
  }
`;
