import React from "react";
import ActionHOC from "components/General/ActionHOC";
import get from "lodash.get";
import GeneralAction from "components/General/GeneralAction";

const Register = (props: any) => {
  const onClick = get(props, "onClick", () => {});
  return (
    <div className="hContainer flexCenter">
      <button className="registerButton" onClick={GeneralAction}>
        REGISTER
      </button>
    </div>
  );
};

export default ActionHOC(Register);
