import React from "react";
import get from "lodash.get";
import { getEventBadges } from "graphql/queries/eventQueries";
import { useQuery } from "@apollo/client";
import HorizontalBadges from "components/Badges/HorizontalBadges";

const EventBadges = (props: any) => {
  const eventId = Number.parseInt(get(props, "eventId", -1));
  const { loading, data, error } = useQuery(getEventBadges, {
    fetchPolicy: "no-cache",
    variables: { venue_event_id: eventId },
  });
  const badges = get(data, "eventBadges.data", []);
  if (badges && Array.isArray(badges) && badges.length > 0) {
    return (
      <>
        <HorizontalBadges badges={badges}></HorizontalBadges>
      </>
    );
  }
  return null;
};

export default EventBadges;
