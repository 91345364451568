import gql from "graphql-tag";

export const manufacturerFragment = gql`
  fragment manufacturerFrag on Manufacturer {
    __typename
    vehicle_manufacturer_id
    name
    is_active
    created_at
    updated_at
    logo
  }
`;
